"use client";

import React, { FC, useEffect, useRef } from 'react';

type KonamiCodeProps = {
  children: React.ReactNode;
};

const KonamiCode: FC<KonamiCodeProps> = ({ children }) => {
  const containerRef = useRef(null); // Referencia al elemento contenedor

  const konamiCode = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
  ];

  let konamiCodePosition = 0;

  const handleKeyDown = (event: any) => {
    const requiredKey = konamiCode[konamiCodePosition];

    if (event.key !== requiredKey) {
      konamiCodePosition = 0;
      return;
    }

    konamiCodePosition++;

    if (konamiCodePosition === konamiCode.length) {
      console.log('Konami Code!')
      const container = containerRef.current as unknown as HTMLDivElement;
      if (container) {
        container.style.transition = 'transform 2s ease';
        container.style.transform = 'rotateY(360deg)';
        konamiCodePosition = 0;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={containerRef} id="main-content">
      {children}
    </div>
  );
};

export default KonamiCode;