"use client";

import React from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import { CookiesProvider } from "react-cookie";

function Providers({ children }: React.PropsWithChildren) {
  const [client] = React.useState(new QueryClient());

  return (
    <>
      <ToastContainer
        position='bottom-center'
        toastClassName="!bg-black-900 !text-white-100"
        theme='dark'
        autoClose={2000}
      />
      <QueryClientProvider client={client}>
        <CookiesProvider>
          {children}
        </CookiesProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default Providers;
